export const amplifyConfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_cognito_identity_pool_id: process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  aws_cognito_region: process.env.REACT_APP_AWS_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_AWS_USER_POOLS_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID
};

export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  // apiKey: 'AIzaSyC6Oq6fkUD00QZ6FhzjnmHlSZw9EtO_tjg', //process.env.REACT_APP_FIREBASE_API_KEY,
  // appId: "1:375544685183:web:44e51530f851e87a62d54a",
  // authDomain: 'armi-24934.firebaseapp.com', //process.env.REACT_APP_FIREBASE_AUTH_DOMAIN',
  // databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  // messagingSenderId: "375544685183",
  // projectId: "armi-24934",
  apiKey: 'AIzaSyBLGaSUuGnm-6HjyMWj5UHyFXY7ByNjlCM', //process.env.REACT_APP_FIREBASE_API_KEY,
  appId: "1:158569837280:web:44e51530f851e87a62d54a",
  authDomain: 'taxigarage.firebaseapp.com', //process.env.REACT_APP_FIREBASE_AUTH_DOMAIN',
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  messagingSenderId: "158569837280",
  projectId: "taxigarage",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
};

export const gtmConfig = {
  //containerId: '375544685183-qc8as635n4jr3dhs7pvirigqmvmqcb53.apps.googleusercontent.com'  //process.env.REACT_APP_GTM_CONTAINER_ID
  containerId: '158569837280-3pvdacja56cvf2pf6mojluuso42kao3e.apps.googleusercontent.com'
};


//Release
const value = 'driver';



//location
//const value = '';

const domain = '/' + value;

export  const config = {
  language: 'ru',
  settings: {
  compact: true,
  direction: 'ltr',
  responsiveFontSizes: true,
  roundedCorners: true,
  theme: 'DARK',
  snowfall: false,
  forecasting:true,
  posting:true,
  plateHidding:true
  },
  baseUrl:   domain,
  urlPrint: 'https://armi.by/' + value,
  apiUrlAi: 'https://armi.by/ai',


        //Release


	     // apiUrl: 'http://178.172.201.172:8055',
         apiUrl: 'https://armi.by/api_release',
      //  apiUrlGarage: 'https://armi.by/api_test',
        apiUrlGarage: 'https://armi.by/api_release',
    //    apiUrlGarage: 'http://localhost:8080',
         base:'prod'

      //org_xwOkFjk
      //grv635td

       //Test проб.
       //org_3rFFpLl lsnvjh19
     	//apiUrl: 'http://178.172.201.172:8087',
    //  apiUrl: 'https://armi.by/api_test',
    //  base:'test'


         //Local
      /*
      apiUrl: 'http://localhost:8080',
      base:'test'
      */
};
