import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import {config}  from 'config';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import { Grid } from '@material-ui/core';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import toast from 'react-hot-toast';

import { connect } from 'react-redux';
import {SuccessSnackbar, ErrorSnackbar,InfoSnackbar,WarningSnackbar} from 'comp/Snackbar';
import { sign, decode, JWT_SECRET, JWT_EXPIRES_IN } from 'ut/jwt';
import translationRu from "locales/translationRu.json";

const useStyles = makeStyles(() => ({
  root: {}
}));

export function translate(param,id){
  let objectJson = null;
  console.log('translate');
  try {
    if(param){
      objectJson = translationRu;
      let arrParam = param.split('.');
      arrParam.forEach(function(name, i) {
      objectJson = objectJson[name];
      });
    if(id){
      objectJson = objectJson[id];
    }}
} catch (err) {
  objectJson = null;
  console.log('no element to translate ' + param + " " + id);
}
  return objectJson;
}

export function failedToFetch(error){
  if(error === 'Failed to fetch' || error.message === 'Failed to fetch'){
    console.log("Cервер недоступен!");
    setItem('message', "Cервер недоступен! Приносим свои извенения. ");
    setItem('info', true);
    setItem('status', 10);
    setItem('redirect', true);
  }
}

export function handleResponse(response){
   return response.text().then(text => {
       const data = text && JSON.parse(text);
      // console.log(data);
       if (!response.ok) {
           if (response.status === 500 || response.status === 400) {
               setItem('message', "Произошла ошибка. Приносим свои извенения!");
               setItem('status', response.status);
               setItem('error', true);
           } else if(response.status === 401){
             console.log("Cервер недоступен!");
             setItem('message', "Произошла ошибка! Сервер недоступен! Приносим свои извенения. ");
             setItem('error', true);
             setItem('status', 10);
             setItem('redirect', true);
           }
           const error = (data && data.message) || response.statusText;
           return Promise.reject(error);
       }

       return data;
   });
}

export function setMessage(message){
  setItem('message', message);
}

export function setStatus(status){
  setItem('status', status);
}

export function setItem(name,object){
  localStorage.setItem(name, JSON.stringify(object));
}

export function renderRedirect(redirect){
  const redirectValue = getItem('redirectValue');
   if (getItem('redirect')) {
     setItem('redirect',false);
      switch(getItem('status')){
        case '401':
        return <Redirect to='/errors/error-401' />
        break;
        case '404':
        return <Redirect to='/errors/error-404' />
        break;
        case '500':
        return <Redirect to='/errors/error-500' />
        break;
      }
     return <Redirect to='/' />
   }
 }

export function getItem(name){
  switch (name) {
    case 'redirect':
    return (localStorage.getItem(name) === 'true');
    case 'success':
    return (localStorage.getItem(name) === 'true' );
    case 'info':
    return (localStorage.getItem(name) === 'true');
    case 'error':
    return (localStorage.getItem(name) === 'true');
    case 'warning':
    return (localStorage.getItem(name) === 'true');
    case 'message':
    return localStorage.getItem(name)!=null && localStorage.getItem(name)!='null' ? localStorage.getItem(name):'';
    case 'status':
    return localStorage.getItem(name);
    default:
    return JSON.parse(localStorage.getItem(name));
  }
}

export function initRepositoryEx(){
    setItem('dataEx', null);
    setItem('pagination', null);
}

export function initRepository(){
  setItem('message', null);
  setItem('status', null);
  setItem('info', null);
  setItem('redirect', false);
  setItem('data', null);
  setItem('dataEx', null);
  setItem('pagination', null);
  setItem('success', false);
  setItem('info', false);
  setItem('error', false);
  setItem('warning', false);
}

export function setPagination(data){
  console.log('setPagination');
  var pagination = {
    "last" : data.last ? data.last: null,
    "totalPages" : data.totalPages ? data.totalPages : null,
    "totalElements" : data.totalElements ? data.totalElements : null,
    "size" : data.size ? data.size : null,
    "number" : data.number ? data.number : null,
    "numberOfElements" : data.numberOfElements ? data.numberOfElements: null,
    "sort" : {
      "direction" : data.sort && data.sort.length>0 && data.sort[0].direction ? data.sort[0].direction : null,
      "property" : data.sort && data.sort.length>0 && data.sort[0].property ? data.sort[0].property : null,
      "ignoreCase" : data.sort && data.sort.length>0 && data.sort[0].ignoreCase ? data.sort[0].ignoreCase : null,
      "nullHandling" : data.sort && data.sort.length>0 && data.sort[0].nullHandling ? data.sort[0].nullHandling : null,
      "ascending" : data.sort && data.sort.length>0 && data.sort[0].ascending ? data.sort[0].ascending : null,
      "descending" : data.sort && data.sort.length>0 && data.sort[0].descending ? data.sort[0].descending : null
    },
    "first" : data.first
  }
  setItem('pagination', pagination);
return pagination;
}

export function getCars(page,size,sort,order,init){

  const data = [{
    id: 1,
    buttonText: "string",
    createdTime: "2020-12-05T10:55:57.047Z",
    message: "string",
    notificationSender: "ARMI",
    notificationType: "NO_BUTTONS",
    read: true,
    readStrategy: "ON_BUTTON_CLICK",
    title: "string"
  },
  {
    id: 2,
    buttonText: "string",
    createdTime: "2020-12-05T10:55:57.047Z",
    message: "string",
    notificationSender: "ARMI",
    notificationType: "NO_BUTTONS",
    read: true,
    readStrategy: "ON_BUTTON_CLICK",
    title: "string"
  },
  {
    id: 3,
    buttonText: "string",
    createdTime: "2020-12-05T10:55:57.047Z",
    message: "string",
    notificationSender: "ARMI",
    notificationType: "NO_BUTTONS",
    read: true,
    readStrategy: "ON_BUTTON_CLICK",
    title: "string"
  },
  {
    id: 4,
    buttonText: "string",
    createdTime: "2020-12-05T10:55:57.047Z",
    message: "string",
    notificationSender: "ARMI",
    notificationType: "NO_BUTTONS",
    read: true,
    readStrategy: "ON_BUTTON_CLICK",
    title: "string"
  },
  {
    id: 5,
    buttonText: "string",
    createdTime: "2020-12-05T10:55:57.047Z",
    message: "string",
    notificationSender: "ARMI",
    notificationType: "NO_BUTTONS",
    read: true,
    readStrategy: "ON_BUTTON_CLICK",
    title: "string"
  },
  {
    id: 6,
    buttonText: "string",
    createdTime: "2020-12-05T10:55:57.047Z",
    message: "string",
    notificationSender: "ARMI",
    notificationType: "NO_BUTTONS",
    read: true,
    readStrategy: "ON_BUTTON_CLICK",
    title: "string"
  }];

  setItem('data', data);
  init(data);
  var pagination = {
    last : 1 ,
    totalPages : 2 ,
    totalElements : 20,
    size : 10,
    number : 1,
    numberOfElements :20
  }
  setItem('pagination', pagination);
  return;

  var sorting = "id";
  switch (sort) {
    case 'id':
      sorting = "id";
      break;
    case 'buttonText':
      sorting = "buttonText";
    break;
    case 'createdTime':
      sorting = "createdTime";
    break;
    case 'message':
      sorting = "message";
    break;
    case 'notificationSender':
      sorting = "notificationSender";
    break;
    case 'notificationType':
      sorting = "notificationType";
    break;
    case 'read':
      sorting = "readed";
    break;
    case 'readStrategy':
      sorting = "readStrategy";
    break;
    case 'title':
      sorting = "notification.title";
    break;
    default:
      sorting = "id";
  }
   sorting+= (order==='desc' ? "," + "desc": "," + "asc" );
  const user = getItem('user');
  console.log(`${config.apiUrl}/api/organization/notifications/all` +
    "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting , requestOptions);
   const requestOptions = {
       method: 'GET',
       headers: {
                   'Content-Type': 'application/json',
                   'Access-Token': user.token
               }
   };

   return fetch(`${config.apiUrl}/api/organization/notifications/all` +
     "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting,
      requestOptions).then(handleResponse)
       .then(data => {
         setPagination(data);
         setItem('data', data.content);
         init(data.content);
       }).catch(response => {
         failedToFetch(response);
       });
}


export async function addImagesGalary(data){
  console.log('addImagesGalary');
  console.log(`${config.apiUrl}/api/publicapi/driver/upload_file/` + `?key=` + 'jSfQ9ufaaP5r4gftdz5Uy' );
    const requestOptions = {
        method: 'POST',
        body: data.img
    };
    return  fetch(`${config.apiUrl}/api/publicapi/driver/upload_file/`+ `?key=` + 'jSfQ9ufaaP5r4gftdz5Uy', requestOptions)
        .then(handleResponse)
        .then(result => {
          setItem('message', "Фото успешно добавлено!");
          setItem('success',true);
          return result;
        }).catch(response => {
          failedToFetch(response);
          return 'fail';
        });

}

export async function addDriverRegistration(data,after){
  console.log(`${config.apiUrl}/api/publicapi/driver/registration_request/` + `?key=` + 'jSfQ9ufaaP5r4gftdz5Uy' );
  //data.key = 'jSfQ9ufaaP5r4gftdz5Uy';
    const requestOptions = {
        method: 'POST',
        headers: {
                    'Content-Type': 'application/json'
                },
        body: JSON.stringify(data)
    };
    return  fetch(`${config.apiUrl}/api/publicapi/driver/registration_request/` + `?key=` + 'jSfQ9ufaaP5r4gftdz5Uy', requestOptions)
        .then(handleResponse)
        .then(result => {
          setItem('message', "Ваша заявка принята. Мы свяжемся с Вами в ближайшее время");
          setItem('success',true);
          after(result);
          return result;
        }).catch(response => {
          failedToFetch(response);
          after('bad');
          return null;
        });

}

// регистрация

export async function register( phone, password ) {
  return new Promise((resolve, reject) => {
    try {
      console.log(`${config.apiUrlGarage}/api/v1/garage/authorization/registration`);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                                  phone: phone,
                                  password: password
                    })
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/authorization/registration`, requestOptions)
            .then(handleResponse)
            .then(accessToken => {
            //  accessToken = '33213hh32j345j4j6h57h3k5k7j6h8h4j5k7jk57j64j76k4';
                // login successful if there's a jwt token in the response
            //    if (accessToken) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
              //      sessionStorage.setItem('accessToken', JSON.stringify(accessToken));
              //      localStorage.setItem('accessToken', JSON.stringify(accessToken));
              //      resolve(accessToken);
              //  }else{
                  resolve('success');
              //  }
                return 'success';
            }).catch(response => {
              console.log(response);
              if(response==='unprocessable.garage.already-registered'){
                toast.success('Такой номер у нас уже зарегистрирован!',{duration: 6000},);
                resolve('existed_user');
              }else if(response==='unprocessable.registration.require-confirm' || response==='unprocessable.garage.require-confirm'){
                toast.success('Ваш sms не подтверждён! Мы вышлим Вам sms для подтверждения',{duration: 6000},);
                resolve('no_sms');
              }else if(response==='not-found.garage' || response==='unprocessable.garage.not-required'){
                toast.success('Номера телефона, на который отправляется смс нет в нашей базе!',{duration: 6000},);
                resolve('fail');
              }else{
                toast.error('Извините! Что-то пошло не так при регистрации!',{duration: 6000},);
                resolve('fail');
              }
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {

      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function changePhone(phone) {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log(`${config.apiUrlGarage}/api/v1/garage/profile/change_phone`);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                        'Access-Token': user.token
                    },
                    body: JSON.stringify({
                    newPhone: phone,
                    })
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/profile/change_phone`, requestOptions)
            .then(handleResponse)
            .then(data => {
              if(data === 'unprocessable.registration.sms_wait'){
                toast.success('К сожалению у Вас закончились попытки!',{duration: 6000},);
                resolve('fail');
              }
                resolve(data);
                return 'success';
            }).catch(response => {
              console.log(response);
              if(response==='unprocessable.garage.already-registered'){
                toast.success('Такой номер у нас уже зарегистрирован!',{duration: 6000},);
                resolve('existed_user');
              }else if(response==='not-found.garage' || response==='unprocessable.garage.not-required'){
                toast.success('Номера телефона, на который отправляется смс нет в нашей базе!',{duration: 6000},);
                resolve('fail');
              }else  if(response === 'unprocessable.registration.sms_wait'){
                toast.success('К сожалению у Вас закончились попытки! Подождите и поробуйте заново!',{duration: 6000},);
                resolve('fail');
              }else{
                toast.error('Извините! Что-то пошло не так при регистрации!',{duration: 6000},);
                resolve('fail');
              }
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {

      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function smsConfirm( phone, code ) {
  return new Promise((resolve, reject) => {
    try {
      console.log(`${config.apiUrlGarage}/api/v1/garage/authorization/registration/confirm_sms`);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                                  phone: phone,
                                  code: code
                    })
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/authorization/registration/confirm_sms`, requestOptions)
            .then(handleResponse)
            .then(user => {
              console.log(user);
                // login successful if there's a jwt token in the response
                if (user) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    sessionStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('user', JSON.stringify(user));
                    resolve(user);
                }else{
                  resolve('no_user');
                }
                return 'sucsess';
            }).catch(response => {
              console.log(response);
              if(response==='unprocessable.garage.already-registered'){
                toast.success('Такой номер уже зарегистрирован у нас',{duration: 6000},);
                resolve('existed_user');
              }else if(response==='unprocessable.registration.require-confirm' || response==='unprocessable.garage.require-confirm'){
                toast.success('Ваш sms не подтверждён!',{duration: 6000},);
                resolve('fail');
              }else if(response==='not-found.garage' || response==='unprocessable.garage.not-required'){
                toast.success('Номера телефона, на который отправляется смс нет в нашей базе! Пройдите регистрацию',{duration: 6000},);
                resolve('fail');
              }else if(response==='unprocessable.registration.incorrect-code' || response==='unprocessable.garage.incorrect-code'){
                toast.success('Введён не верный смс! Введите другой код!',{duration: 6000},);
                resolve('fail');
              }else{
                toast.error('Извините! Что-то пошло не так при регистрации! Попробуйте еще раз через какое-то время',{duration: 6000},);
                resolve('fail');
              }
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function smsResend( phone ) {
  return new Promise((resolve, reject) => {
    try {
      console.log(`${config.apiUrlGarage}/api/v1/garage/authorization/sms/resend`);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                                  phone: phone,
                                  appType: 0
                    })
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/authorization/sms/resend`, requestOptions)
            .then(handleResponse)
            .then(sms => {
              console.log(sms);
                  resolve(sms);
                return 'sucsess';
            }).catch(response => {
              console.log(response);
              if(response==='not-found.garage'){
                toast.success('Номера телефона, на который отправляется смс нет в базе. Пройдите регистрацию!',{duration: 6000},);
                resolve('existed_user');
              }else if(response==='unprocessable.garage.not-required'){
                toast.success('Ваш телефон уже подтверждён! Пожалуйста воспользуйтесь формой авторизации для входа!',{duration: 6000},);
                resolve('fail');
              }else if(response==='unprocessable.registration.sms_timeout'){
                toast.success('Смс уже посылалась недавно, повторите попытку через минуту!',{duration: 6000},);
                resolve('fail');
              }else if(response==='unprocessable.registration.attempt_limit'){
                toast.success('Превышено количество запросов на отправку смс! Подождите и попробуйте снова отправить sms код',{duration: 6000},);
                resolve('fail');
              }else{
                toast.success('Извините! Что-то пошло не так! Не можем запросить sms для Вас',{duration: 6000},);
                resolve('fail');
              }
              resolve('fail');
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}


export async function getProfileToken() {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log('getProfileToken');
      console.log(user);
      console.log(`${config.apiUrlGarage}/api/v1/garage/profile/`);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Access-Token': user.token
                  }
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/profile/`, requestOptions)
            .then(handleResponse)
            .then(profile => {
                window.localStorage.setItem('profile', JSON.stringify(profile));
                  resolve(profile);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getProfileToken error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getProfileToken error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function login( phone, password ) {
  return new Promise((resolve, reject) => {
    try {
      console.log(`${config.apiUrlGarage}/api/v1/garage/authorization/login`);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                                  phone: phone,
                                  password: password
                    })
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/authorization/login`, requestOptions)
            .then(handleResponse)
            .then(user => {
              console.log(user);
                // login successful if there's a jwt token in the respons
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    sessionStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('user', JSON.stringify(user));
                    resolve(user);
                return 'sucsess';
            }).catch(response => {
              console.log(response);
                if(response === 'forbidden.authorization.invalid-credentials'){
                toast.success('Извините! Вы ввели не верный телефон и пароль! ',{duration: 6000},);
              }else{
                toast.success('Извините! Что-то не так! Мы не можем дать Вам доступ! Попробуйте позже.',{duration: 6000},);
              }
                resolve('fail');

              failedToFetch(response);
              return 'fail';
            });
    } catch (err) {
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function loginGoogle( profile ) {
  return new Promise((resolve, reject) => {
    try {
      console.log(`${config.apiUrlGarage}/api/v1/garage/authorization/oauth_login`);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                    },
            body: JSON.stringify(profile)
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/authorization/oauth_login`, requestOptions)
            .then(handleResponse)
            .then(user => {
              console.log(user);
                // login successful if there's a jwt token in the respons
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    sessionStorage.setItem('user', JSON.stringify(user));
                    localStorage.setItem('user', JSON.stringify(user));
                    resolve(user);
                return 'sucsess';
            }).catch(response => {
              console.log(response);
              resolve('fail');
              failedToFetch(response);
              return 'fail';
            });
    } catch (err) {
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function logout( ) {
  const user = getItem('user');
  return new Promise((resolve, reject) => {
    try {
      console.log(`${config.apiUrlGarage}/api/v1/garage/authorization/logout`);
      if(user!== null && user!==undefined && user!=='undefined'){
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                        'Access-Token': user.token
                    }
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/authorization/logout`, requestOptions)
            .then(handleResponse)
            .then(user => {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    sessionStorage.setItem('user', null);
                    localStorage.setItem('user', null);
                    resolve('success');
                return 'sucsess';
            }).catch(response => {
              console.log(response);
              //  toast.error('Извините! Что-то пошло не так! Не можем достуаться до сервера',{duration: 6000},);
              resolve('fail');
              failedToFetch(response);
              return 'fail';
            });
          }
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }

  });
}

export async function me(accessToken) {
  return new Promise((resolve, reject) => {
    try {
      // Decode access token
      const { userId } = decode(accessToken);

      resolve({
        id: user.id,
        avatar: user.avatar,
        email: user.email,
        name: user.name,
        phone: user.phone
      });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Internal server error'));
    }
  });
}


export async function uploadAvatar(image){
 const user = getItem('user');
  return new Promise((resolve, reject) => {
    try {
      console.log('uploadAvatar');
      console.log(`${config.apiUrlGarage}/api/garage/images/upload/GARAGE_AVATAR`);

        const requestOptions = {
            crossDomain:false,
            method: 'POST',
            headers: {'Access-Token': user.token},
            body: image
        };

        return  fetch(`${config.apiUrlGarage}/api/garage/images/upload/GARAGE_AVATAR`, requestOptions)
            .then(handleResponse)
            .then(result => {
              setItem('success',true);
              resolve(result);
              return result;
            }).catch(response => {
              failedToFetch(response);
              console.log(response);
              resolve('fail');
              return 'fail';
            });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Internal server error'));
    }
  });
}

export async function addAvatar(id){
 const user = getItem('user');
  return new Promise((resolve, reject) => {
    try {
      console.log('addAvatar');
      console.log(`${config.apiUrlGarage}/api/v1/garage/profile/avatar/?` + 'avatarId=' + id);
        const requestOptions = {
            crossDomain:true,
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                        'Access-Token': user.token
                    }
        };
        return  fetch(`${config.apiUrlGarage}/api/v1/garage/profile/avatar/?` + 'avatarId=' + id, requestOptions)
            .then(handleResponse)
            .then(result => {
              toast.success('Спасибо! Ваше фото добавлено! ',{duration: 6000},);
              resolve(result);
              return result;
            }).catch(response => {
              failedToFetch(response);
              resolve('fail');
              return 'fail';
            });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      reject(new Error('Internal server error'));
    }
  });
}


export async function addFotoCar(image,bluring){
 const user = getItem('user');
  return new Promise((resolve, reject) => {
    try {
      console.log('addFotoCar');
      console.log(`${config.apiUrlGarage}/api/garage/images/upload/CAR_GALLERY/?blurred=` + bluring);

        const requestOptions = {
            crossDomain:false,
            method: 'POST',
            headers: {'Access-Token': user.token},
            body: image
        };
        console.log(user.token);
        return  fetch(`${config.apiUrlGarage}/api/garage/images/upload/CAR_GALLERY?blurred=` + bluring, requestOptions)
            .then(handleResponse)
            .then(result => {
              setItem('success',true);
              resolve(result);
              return result;
            }).catch(response => {
              if(response == "unprocessable.image.convert.unknown_format"){
                toast.success('Некорректный формат фотографии!',{duration: 6000},);
              }
              failedToFetch(response);
              console.log(response);
              resolve('fail');
              return 'fail';
            });
    } catch (err) {
      console.error('[Auth Api]: ', err);

      throw new Error("Internal server error");
      reject(new Error('Internal server error'));
    }
  });
}

export  async function checkRecognizeCarPoto(image,angle,degre){
 const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
      console.log('checkManPoto');
      console.log(`${config.apiUrlAi}/recognize/car/angle/json?angle=` + `${angle}` + `&degre=` + `${degre}`);

        const requestOptions = {
            method: 'POST',
            body:image
        };

        return  fetch(`${config.apiUrlAi}/recognize/car/angle/json?angle=`+  `${angle}` + `&degre=` + `${degre}`, requestOptions)
            .then(handleResponse)
            .then(result => {
              if(result.result==1){
                   resolve(result);
                   setItem('success',true);
              }else{
                   resolve(result);
                   setItem('warning',true);
              }
              console.log(result);
              return result;
            }).catch(response => {
              console.log(response);
              resolve('fail');
            });
    } catch (err) {
      console.error('[Auth Api]: ', err);
    }
    });
}

export  async function checkCarPoto(image){
 const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
      console.log('checkManPoto');
      console.log(`${config.apiUrlAi}/recognize/car/json`);

        const requestOptions = {
            method: 'POST',
            body:image
        };

        return  fetch(`${config.apiUrlAi}/recognize/car/json`, requestOptions)
            .then(handleResponse)
            .then(result => {
              if(result.result==1){
                   resolve(result);
                   setItem('success',true);
              }else{
                   resolve(result);
                   setItem('warning',true);
              }
              console.log(result);
              resolve('fail');
              return result;
            }).catch(response => {
              console.log(response);
              resolve('fail');
              return 'fail';
            });
    } catch (err) {
      console.error('[Auth Api]: ', err);
    }
    });
}

export  async function blurPlateCarPoto(image){
 const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
      console.log('blurPlateCarPoto');
      console.log(`${config.apiUrlAi}/recognize/car/wholeplate/json`);

        const requestOptions = {
            method: 'POST',
            body:image
        };

        return  fetch(`${config.apiUrlAi}/recognize/car/wholeplate/json`, requestOptions)
            .then(handleResponse)
            .then(result => {
              if(result.result==1){
                   resolve(result);
                   setItem('success',true);
              }else{
                   resolve(result);
                   setItem('warning',true);
              }
              console.log(result);
              return result;
            }).catch(response => {
              console.log(response);
              resolve('fail');
              return 'fail';
            });
    } catch (err) {
      console.error('[Auth Api]: ', err);
      resolve('fail');
    }
    });
}



export async function addInfoUserProfile(data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrlGarage}/api/v1/garage/profile/`);
       const requestOptions = {
           method: 'POST',
           headers: {
                       'Content-Type': 'application/json',
                       'Access-Token': user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrlGarage}/api/v1/garage/profile/`, requestOptions)
           .then(handleResponse)
           .then(result => {
             //toast.success('Спасибо! Ваше фото добавлено! ',{duration: 6000},);
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             console.log('addInfoUserProfile');
             resolve('fail');
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}

export async function changePassword(password){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrlGarage}/api/v1/garage/profile/change_password`);
       const requestOptions = {
           method: 'POST',
           headers: {
                       'Content-Type': 'application/json',
                       'Access-Token': user.token
                   },
           body: JSON.stringify({
            newPassword: password
          })
       };
       return  fetch(`${config.apiUrlGarage}/api/v1/garage/profile/change_password`, requestOptions)
           .then(handleResponse)
           .then(result => {
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             console.log('changePassword');
             resolve('fail');
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}


export async function addCarToGarage(data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrlGarage}/api/v1/garage/car/ad/`);
       const requestOptions = {
           method: 'POST',
           headers: {
                       'Content-Type': 'application/json',
                       'Access-Token': user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrlGarage}/api/v1/garage/car/ad/`, requestOptions)
           .then(handleResponse)
           .then(result => {
             console.log('addCarToGarage');
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             console.log('addCarToGarage');
             resolve('fail');
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}

export async function updateCarToGarage(id,data){
  const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
     console.log(`${config.apiUrlGarage}/api/v1/garage/car/ad/update/` + id);
       const requestOptions = {
           method: 'POST',
           headers: {
                       'Content-Type': 'application/json',
                       'Access-Token': user.token
                   },
           body: JSON.stringify(data)
       };
       return  fetch(`${config.apiUrlGarage}/api/v1/garage/car/ad/update/` + id, requestOptions)
           .then(handleResponse)
           .then(result => {
             console.log('updateCarToGarage');
             resolve(result);
             return result;
           }).catch(response => {
             failedToFetch(response);
             console.log('updateCarToGarage');
             resolve('fail');
             return 'fail';
           });
         } catch (err) {
           console.error('[Auth Api]: ', err);
           reject(new Error('Internal server error'));
         }
   });

}


export async function getChipTarifs() {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log('getChipTarifs');
      console.log(user);
      console.log(`${config.apiUrlGarage}/api/v1/garage/car/ad/types/supported_tariffs/`);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Access-Token': user.token
                  }
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/car/ad/types/supported_tariffs/`, requestOptions)
            .then(handleResponse)
            .then(tarifs => {
              console.log(tarifs);
                  resolve(tarifs);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getProfileToken error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getChipTarifs error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}


export async function getChipEquipments() {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log('getChipEquipment');
      console.log(user);
      console.log(`${config.apiUrlGarage}/api/v1/garage/car/ad/types/equipment/`);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Access-Token': user.token
                  }
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/car/ad/types/equipment/`, requestOptions)
            .then(handleResponse)
            .then(equipment => {
                console.log(equipment);
                  resolve(equipment);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getChipEquipment error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getChipTarifs error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function getChipTaxi() {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log('getChipTaxi');
      console.log(user);
      console.log(`${config.apiUrlGarage}/api/v1/garage/car/ad/types/park_supported_taxi/`);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Access-Token': user.token
                  }
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/car/ad/types/park_supported_taxi/`, requestOptions)
            .then(handleResponse)
            .then(taxi => {
              console.log(taxi);
                  resolve(taxi);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getChipTaxi error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getChipTaxi error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}



export async function getTaxisAdmin(page,size,sort,order,status,search = null) {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log('getTaxisAdmin');
      console.log(user);

      var sorting = "id";
      switch (sort) {
        case 'id':
          sorting = "id";
          break;
        case 'regNumber':
          sorting = "regNumber";
        break;
        case 'status':
          sorting = "status";
        break;
        case 'carInfo':
          sorting = "automaker,model,year";
        break;
        case 'showDate':
          sorting = "publishTime,stopPublishTime";
        break;
        case 'viewed':
          sorting = "viewed";
        break;
        default:
          sorting = "id";
      }
      sorting+= (order==='desc' ? "," + "desc": "" );

      const searchStr = search!=='undefined' && search!== null && search!==undefined && search!=='' ? "&search=" + search : "";
      const statusStr = status!=='undefined' && status!== null && status!==undefined && status!=='' ? "&adStatus=" + status : "";

      console.log(`${config.apiUrlGarage}/api/v1/garage/car/ad/` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + searchStr + statusStr);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Access-Token': user.token
                  }
        };

        return fetch(`${config.apiUrlGarage}/api/v1/garage/car/ad/` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + searchStr + statusStr, requestOptions)
            .then(handleResponse)
            .then(taxis => {
              console.log(taxis);
              setItem('pagination', taxis);
              setItem('data', taxis.content);
              console.log(taxis);
              resolve(taxis);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getTaxisAdmin error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getChipTaxi error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function getChangeStatus(id,status) {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log('getChangeStatus');
      console.log(user);
      console.log(`${config.apiUrlGarage}/api/v1/garage/car/ad/status/` + id  + "?ad_id=" + id + "&ad_status=" +  status);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Access-Token': user.token
                  }
        };
        return fetch(`${config.apiUrlGarage}/api/v1/garage/car/ad/status/` +  id  + "?ad_id=" + id + "&ad_status=" +  status, requestOptions)
            .then(handleResponse)
            .then(status => {
                console.log(status);
                resolve(status);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getChangeStatus error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getChangeStatus error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function deleteAnnouncement(ids) {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log(user);
        const requestOptions = {
            method: 'POST',
            headers: {
                        'Content-Type': 'application/json',
                        'Access-Token': user.token
                    },
            body: JSON.stringify({ids:ids})
        };
        return fetch(`${config.apiUrlGarage}/api/v1/garage/car/ad/delete`, requestOptions)
            .then(handleResponse)
            .then(result => {
                resolve(result);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('deleteAnnouncement error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('deleteAnnouncement error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}


export async function getAnnouncement(id) {
  return new Promise((resolve, reject) => {
    try {
      const user = getItem('user');
      console.log('getAnnouncement');
      console.log(user);
      console.log(`${config.apiUrlGarage}/api/v1/garage/car/ad/` + id );
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Access-Token': user.token
                  }
        };
        return fetch(`${config.apiUrlGarage}/api/v1/garage/car/ad/` + id,requestOptions )
            .then(handleResponse)
            .then(announcement => {
                console.log(announcement);
                resolve(announcement);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getAnnouncement error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getChangeStatus error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function getPublicChipTarifs() {
  return new Promise((resolve, reject) => {
    try {
      console.log('getPublicChipTarifs');
      console.log(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/supported_tariffs/`);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json'
                  }
        };

        return fetch(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/supported_tariffs/`, requestOptions)
            .then(handleResponse)
            .then(tarifs => {
              console.log(tarifs);
                  resolve(tarifs);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getPublicChipTarifs error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getPublicChipTarifs error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}


export async function getPublicChipEquipments() {
  return new Promise((resolve, reject) => {
    try {
      console.log('getPublicChipEquipments');
      console.log(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/equipment/`);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json'
                  }
        };

        return fetch(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/equipment/`, requestOptions)
            .then(handleResponse)
            .then(equipment => {
                console.log(equipment);
                  resolve(equipment);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getPublicChipEquipments error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getPublicChipEquipments error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function getPublicChipTaxi() {
  return new Promise((resolve, reject) => {
    try {
      console.log('getPublicChipTaxi');
      console.log(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/park_supported_taxi/`);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json'
                  }
        };

        return fetch(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/park_supported_taxi/`, requestOptions)
            .then(handleResponse)
            .then(taxi => {
              console.log(taxi);
                  resolve(taxi);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getPublicChipTaxi error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getPublicChipTaxi error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}


export async function getPublicRegion() {
  return new Promise((resolve, reject) => {
    try {
      console.log('getPublicRegion');
      console.log(`${config.apiUrlGarage}/api/publicapi/rentregion/`);
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json'
                  }
        };

        return fetch(`${config.apiUrlGarage}/api/publicapi/rentregion/`, requestOptions)
            .then(handleResponse)
            .then(region => {
              console.log(region);
                  resolve(region);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getPublicRegion error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getPublicRegion error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function getTaxisGarage(page,size,sort,order,offer,city,transmission,fuel,option,tarif,equipment,support) {
  return new Promise((resolve, reject) => {
    console.log('offer ' + offer)
    try {
      console.log('getTaxisGarage');
      console.log({
        page:page,
        size: size,
        sort: sort,
        order: order,
        offer: offer,
        city: city,
        transmission: transmission,
        fuel: fuel,
        option: option,
        tarif:tarif,
        equipment: equipment,
        support: support
      });
      var sorting = "orderTime";
      switch (sort) {
        case 'id':
          sorting = "orderTime";
          break;
        default:
          sorting = "orderTime";
      }
      sorting+= (order==='desc' ? "," + "desc": "" );

      let offerStr = '';
      if(offer!=='undefined' && offer!== null && offer!==undefined && offer!==''){
        if(offer==='rent'){
          offerStr = '&rent=true';
        }else if(offer==='buyout'){
          offerStr = '&buyout=true';
        }else if(offer==='deal'){
          offerStr = '&deal=true';
        }
      }

      const cityStr = city!=='undefined' && city!== null && city!==undefined && city!=='' ? "&regionId=" + city : "";
      const transmissionStr = transmission!=='undefined' && transmission!== null && transmission!==undefined && transmission!=='' ? "&transmission=" + transmission : "";
      const fuelStr = fuel!=='undefined' && fuel!== null && fuel!==undefined && fuel!=='' ? "&fuel=" + fuel : "";
      const optionStr = option!=='undefined' && option!== null && option!==undefined && option!=='' ? "&engineAdditionalEquipments=" + option    : "";
      const tarifStr = tarif!=='undefined' && tarif!== null && tarif!==undefined && tarif!=='' ? "&supportedTariffsIds=" + tarif : "";
      const equipmentStr = equipment!=='undefined' && equipment!== null && equipment!==undefined && equipment!=='' ? "&equipmentsIds=" + equipment : "";
      const supportStr = support!=='undefined' && support!== null && support!==undefined && support!=='' ? "&supportedTaxiIds=" + support : "";


      console.log(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + offerStr + cityStr + transmissionStr + fuelStr + optionStr + tarifStr + equipmentStr + supportStr );
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                  }
        };

        return fetch(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + offerStr + cityStr  + transmissionStr + fuelStr + optionStr + tarifStr + equipmentStr + supportStr, requestOptions)
            .then(handleResponse)
            .then(taxis => {
              console.log(taxis);
              setItem('pagination', taxis);
              setItem('data', taxis.content);
              console.log(taxis);
              resolve(taxis);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getTaxisGarage error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getTaxisGarage error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function getTaxisGarageCityName(page,size,sort,order,offer,city,transmission,fuel,option,tarif,equipment,support) {
  return new Promise((resolve, reject) => {
    console.log('offer ' + offer)
    try {
      console.log('getTaxisGarage');
      console.log({
        page:page,
        size: size,
        sort: sort,
        order: order,
        offer: offer,
        city: city,
        transmission: transmission,
        fuel: fuel,
        option: option,
        tarif:tarif,
        equipment: equipment,
        support: support
      });
      var sorting = "orderTime";
      switch (sort) {
        case 'id':
          sorting = "orderTime";
          break;
        default:
          sorting = "orderTime";
      }
      sorting+= (order==='desc' ? "," + "desc": "" );

      let offerStr = '';
      if(offer!=='undefined' && offer!== null && offer!==undefined && offer!==''){
        if(offer==='rent'){
          offerStr = '&rent=true';
        }else if(offer==='buyout'){
          offerStr = '&buyout=true';
        }else if(offer==='deal'){
          offerStr = '&deal=true';
        }
      }

      const cityStr = city!=='undefined' && city!== null && city!==undefined && city!=='' ? "&regionName=" + city : "&regionId=0";
      const transmissionStr = transmission!=='undefined' && transmission!== null && transmission!==undefined && transmission!=='' ? "&transmission=" + transmission : "";
      const fuelStr = fuel!=='undefined' && fuel!== null && fuel!==undefined && fuel!=='' ? "&fuel=" + fuel : "";
      const optionStr = option!=='undefined' && option!== null && option!==undefined && option!=='' ? "&engineAdditionalEquipments=" + option    : "";
      const tarifStr = tarif!=='undefined' && tarif!== null && tarif!==undefined && tarif!=='' ? "&supportedTariffsIds=" + tarif : "";
      const equipmentStr = equipment!=='undefined' && equipment!== null && equipment!==undefined && equipment!=='' ? "&equipmentsIds=" + equipment : "";
      const supportStr = support!=='undefined' && support!== null && support!==undefined && support!=='' ? "&supportedTaxiIds=" + support : "";


      console.log(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + offerStr + cityStr + transmissionStr + fuelStr + optionStr + tarifStr + equipmentStr + supportStr );
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                  }
        };

        return fetch(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/` +   "?" + "page=" + page + "&" + "size=" + size + "&" + "sort=" + sorting + offerStr + cityStr  + transmissionStr + fuelStr + optionStr + tarifStr + equipmentStr + supportStr, requestOptions)
            .then(handleResponse)
            .then(taxis => {
              console.log(taxis);
              setItem('pagination', taxis);
              setItem('data', taxis.content);
              console.log(taxis);
              resolve(taxis);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getTaxisGarage error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });
    } catch (err) {
      console.log('getTaxisGarage error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function getCarGarage(id) {
  return new Promise((resolve, reject) => {
    try {
      console.log('getCarGarage');
      console.log(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/` + id );
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json'
                  }
        };
        return fetch(`${config.apiUrlGarage}/api/v1/publicapi/garage/car/ad/` + id ,requestOptions )
            .then(handleResponse)
            .then(announcement => {
                console.log(announcement);
                resolve(announcement);
                return 'sucsess';
            }).catch(response => {
              resolve('fail');
              console.log('getCarGarage error');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('getCarGarage error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export async function shiftGarage(id) {
  return new Promise((resolve, reject) => {
    try {
      console.log(`${config.apiUrlGarage}/api/v1/garage/car/ad/shift/` + id );
      const user = getItem('user');
        const requestOptions = {
          method: 'GET',
          headers: {
                      'Content-Type': 'application/json',
                      'Access-Token': user.token
                  }
        };
        return fetch(`${config.apiUrlGarage}/api/v1/garage/car/ad/shift/` + id,requestOptions )
            .then(handleResponse)
            .then(data => {
                resolve(data);
                return 'sucsess';
            }).catch(response => {
              if(response==='unprocessable.ad.shift_limit' ){
                toast.success('Извините! Вы не можете сейчас поднять объявление! Вы уже подымали ранее.',{duration: 6000},);
                return;
             }
              resolve('fail');
              console.log(response);
              failedToFetch(response);
              return 'fail';
            });;
    } catch (err) {
      console.log('hsiftGarage error');
      console.log(err);
      resolve('fail');
      console.error('[Auth Api]: ', err);
      reject(new Error('Ошибка сервера'));
    }
  });
}

export  async function postCarPoto(image,degre){
 const user = getItem('user');
   return new Promise((resolve, reject) => {
    try {
      console.log('postCarPoto');
      console.log(`${config.apiUrlAi}/car/photo/post/json`+ `?degre=` + `${degre}`);

        const requestOptions = {
            method: 'POST',
            body:image
        };

        return  fetch(`${config.apiUrlAi}/car/photo/post/json` + `?degre=` + `${degre}`, requestOptions)
            .then(handleResponse)
            .then(result => {
              if(result.result==1){
                   resolve(result);
                   setItem('success',true);
              }else{
                   resolve(result);
                   setItem('warning',true);
              }
              console.log(result);
              return result;
            }).catch(response => {
              console.log(response);
              return 'fail';
            });
    } catch (err) {
      console.error('[Auth Api]: ', err);
    }
    });
}




////////////////////////////КОНЕЦ//////////////////////////////////////




class Service extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
    serviceId: null,
    change:0,
    success:false,
    info:false,
    error:false,
    warning:false
  };

  this.handleSnackbarClose = this.handleSnackbarClose.bind(this);
  this.handleSnackbarInfoClose = this.handleSnackbarInfoClose.bind(this);
  this.handleSnackbarErrorClose = this.handleSnackbarErrorClose.bind(this);
  this.handleSnackbarWarningClose = this.handleSnackbarWarningClose.bind(this);
  this.setChange = this.setChange.bind(this);


  var timer = setInterval(function () {
    if(getItem('redirect')){
      this.setChange();

    }
    setTimeout(() => {
    this.setChange();
    }, 5000);

    if(getItem('error')){
      this.setChange();
      setTimeout(() => {
      setItem('error',false);
      this.handleSnackbarErrorClose();
      this.setChange();
    }, 5000);
    }
    if(getItem('warning')){
      this.setChange();
      setTimeout(() => {
      setItem('warning',false);
      this.handleSnackbarWarningClose();
      this.setChange();
    }, 5000);
    }
    if(getItem('info')){
      this.setChange();
      setTimeout(() => {
      setItem('info',false);
      this.handleSnackbarInfoClose();
      this.setChange();
    }, 5000);
    }
    if(getItem('success')){
      this.setChange();
      setTimeout(() => {
      setItem('success',false);
      this.handleSnackbarClose();
    //  this.setChange();
    }, 5000);
    }

  }.bind(this), 3000);
}

setChange(){
  this.state.change+=1;
  this.setState({ change: this.state.change });
}

  handleSnackbarClose(){
    setItem('success', false);
  };

handleSnackbarInfoClose(){
    setItem('info', false);
  };
handleSnackbarErrorClose(){
    setItem('error', false);
  };
 handleSnackbarWarningClose(){
    setItem('warning', false);
  };

  componentDidMount(){
   this.refresher = setInterval(1000, () => this.forceUpdate())
 }

  componentWillUnmount () {
     clearInterval(this.refresher)
  }



  render() {
    const { classes } = this.props;
    return (
    <div>
    {renderRedirect()}
      <SuccessSnackbar
        onClose={this.handleSnackbarClose}
        open={(getItem('success') )}
        message = {getItem('message')}
      />
      <InfoSnackbar
        onClose={this.handleSnackbarInfoClose}
        open={(getItem('info') )}
        message = {getItem('message')}
      />
      <ErrorSnackbar
        onClose={this.handleSnackbarErrorClose}
        open={(getItem('error') )}
        message = {getItem('message')}
      />
      <WarningSnackbar
        onClose={this.handleSnackbarWarningClose}
        open={(getItem('warning'))}
        message = {getItem('message')}
      />
    </div>
  );
}
};

Service.propTypes = {
  className: PropTypes.string,
  pushMessage: PropTypes.func.isRequired
};

export default connect (
  (state, props) => ({
    authentication: state.authentication,
    queue: state.queue
  }),
  dispatch => ({
    pushMessage: (queue) => {
    dispatch({ type: 'SAVE_QUEUE', payload: queue })
  },
  checkData: (queue) => {
  dispatch({ type: 'CHECK_DATA', payload: queue })
  }
  })
)(Service);
