import { useEffect,useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Box, Card, CardContent, Container, Divider, Link, Typography,IconButton } from '@material-ui/core';
import AuthBanner from '../../components/authentication/AuthBanner';
import {
  LoginAmplify,
  LoginAuth0,
  LoginFirebase,
  LoginJWT
} from '../../components/authentication/login';
import Logo from '../../components/Logo';
import useAuth from '../../hooks/useAuth';
import gtm from '../../lib/gtm';

import useSettings from 'hooks/useSettings';
import MoonIcon from 'icons/Moon';
import SunIcon from 'icons/Sun';
import StarIcon from 'icons/Star';
import { THEMES } from 'constants';
import {config}  from 'config';
import { RememberMeProvider } from '../../contexts/RememberMeContext';
import { GoogleProfileProvider } from '../../contexts/GoogleProfileContext';
import { ProfileProvider } from '../../contexts/ProfileContext';
import { useTranslation } from "react-i18next";

const platformIcons = {
  Amplify: '/static/icons/amplify.svg',
  Auth0: '/static/icons/auth0.svg',
  Firebase: '/static/icons/firebase.svg',
  JWT: '/static/icons/jwt.svg'
};

const Login = () => {
  const { platform } = useAuth();
  const { t, i18n } = useTranslation();
  const { settings, saveSettings } = useSettings();

  const [selectedTheme, setSelectedTheme] = useState(settings.theme);


  const getVal = (settings) => ({
    compact: settings.compact,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    roundedCorners: settings.roundedCorners,
    theme: settings.theme,
    forecasting: settings.forecasting,
    posting:settings.posting,
    plateHidding:settings.plateHidding
  });

   const [val, setVal] = useState(getVal(settings));

  const handleSwitch = () =>  {
    console.log(val.theme);
    if (val.theme === THEMES.LIGHT) {
      val.theme = THEMES.DARK;
      settings.theme = THEMES.DARK;
        setVal({
          ...val,
          ['theme']: THEMES.DARK
        });
        saveSettings(val);
        return THEMES.DARK;
      }

  val.theme = THEMES.LIGHT;
    setVal({
      ...val,
      ['theme']: THEMES.LIGHT
    });
   settings.theme = THEMES.LIGHT;
    saveSettings(val);
    return THEMES.LIGHT;
  };

  useEffect(() => {
    gtm.push({ event: 'page_view' });
  }, []);

  return (
    <RememberMeProvider>
    <ProfileProvider>
    <GoogleProfileProvider>
      <Helmet>
        <title>Login | ARMI</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100vh'
        }}
      >
        <Container
          maxWidth="sm"
          sx={{ py: '80px' }}
        >
          <Card>
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                p: 4
              }}
            >
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  justifyContent: 'space-between',
                  mb: 3
                }}
              >
                <div>
                  <Typography
                    color="textPrimary"
                    gutterBottom
                    variant="h4"
                  >
                    {t('entrance.entranceIn')}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                  >
                    {t('entrance.anotherPlatform')}
                  </Typography>
                </div>
                <Box
                    sx={{
                      height: 32,
                      '& > img': {
                        maxHeight: '100%',
                        width: 'auto'
                      }
                    }}
                  >
                  <IconButton onClick={(e)=>handleSwitch()}>
                    {settings.theme === THEMES.LIGHT
                      ? <MoonIcon fontSize="small" />
                      :  <SunIcon fontSize="small" /> }
                  </IconButton>
                  </Box>
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  mt: 3
                }}
              >
                {platform === 'Amplify' && <LoginAmplify />}
                {platform === 'Auth0' && <LoginAuth0 />}
                {platform === 'Firebase' && <LoginFirebase />}
                {platform === 'JWT' && <LoginJWT />}
              </Box>
              <Divider sx={{ my: 3 }} />
              <Link
                color="primary"
                href={`${config.baseUrl}/garage/admin-garage/register`}
                variant="body2"
                style={{textAlign:'center',marginBottom:'10px'}}
              >
                {t('entrance.register')}
              </Link>
              <Typography
                color="textSecondary"
                variant="body2"
                style={{textAlign:'center'}}
              >
                {t('entrance.agreed1')} <Link target="_blank" color="primary" href={`${config.baseUrl}/user-agreement-garage-converted`} variant="body2">armi.garage</Link> {t('entrance.and')} <Link target="_blank" color="primary" href={`${config.baseUrl}/privacy-policy`} variant="body2">{t('entrance.agreed2')}</Link>
              </Typography>
            </CardContent>
          </Card>
        </Container>
      </Box>
      </GoogleProfileProvider>
      </ProfileProvider>
    </RememberMeProvider>
  );
};

export default Login;
