import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const initialAcount = {
  login: '',
  password: ''
};

export const restoreAcount = () => {
  let rememberMe = null;
  console.log('restoreAcount');
  try {
    const storedData = window.localStorage.getItem('rememberMe');

    if (storedData) {
      rememberMe = JSON.parse(storedData);
    } else {
      rememberMe = {
        login: '',
        password: ''
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return rememberMe;
};

export const storeAcount = (rememberMe) => {
  window.localStorage.setItem('rememberMe', JSON.stringify(rememberMe));
};

const RememberMeContext = createContext({
  rememberMe: initialAcount,
  saveAcount: () => {},
  removeAcount: () => {}
});

export const RememberMeProvider = (props) => {
  const { children } = props;
  const [rememberMe, setRememberMe] = useState(initialAcount);

  useEffect(() => {
    const restoredAcount = restoreAcount();

    if (restoredAcount) {
      setRememberMe(restoredAcount);
    }
  }, []);

  const saveAcount = (updatedAcount) => {
    console.log('saveAcount');
    setRememberMe(updatedAcount);
    storeAcount(updatedAcount);
  };

  const removeAcount = () => {
    console.log('removeAcount');
    setRememberMe({
      login: '',
      password: ''
    });
    storeAcount({
      login: '',
      password: ''
    });
  };

  return (
    <RememberMeContext.Provider
      value={{
        rememberMe,
        saveAcount,
        removeAcount
      }}
    >
      {children}
    </RememberMeContext.Provider>
  );
};

RememberMeProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const RememberMeConsumer = RememberMeContext.Consumer;

export default RememberMeContext;
