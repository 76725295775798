import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import {dataproject} from './dataproject.reducer';
import { users } from './users.reducer';
import { alert } from './alert.reducer';
import queue from './queue.js';
import check_data from './check_data.js';
import tableorders from './tableorders.js';
import addMessage from './addMessage.js';

import organizationsList from './organizationsList.js';
import checkGroup from './checkGroup.js';
import checkGroupTable from './checkGroupTable.js';
import checkOptionTable from './checkOptionTable.js';


const rootReducer = combineReducers({
  authentication,
  registration,
  dataproject,
  users,
  alert,
  queue,
  check_data,
  tableorders,
  addMessage,
  organizationsList,
  checkGroup,
  checkGroupTable,
  checkOptionTable
});

export default rootReducer;
