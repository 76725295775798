import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
import { initReactI18next } from 'react-i18next'
import translationEn from "locales/translationEn.json";
import translationRu from "locales/translationRu.json";

const resources = {
  en: {
    translation: translationEn
  },
  ru: {
    translation: translationRu
  }
};

i18n
.use(Backend)
.use(LanguageDetector)
.use(initReactI18next)
  .use(initReactI18next)
  .init({
    resources,
    lng: 'ru',
    fallbackLng: 'ru',
    interpolation: {
      escapeValue: false
    }
  });
