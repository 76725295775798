import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const initialProfile = {
      avatar: {
        created: "",
        id: 0,
        imageType: "GARAGE_AVATAR",
        pathNormal: "",
        pathSmall: ""
      },
      contactName: "",
      contactPhone: "",
      email: "",
      id: 0,
      orgName: "",
      phone: ""
};

export const restoreProfile = () => {
  let profile = null;
  try {
    const storedData = window.localStorage.getItem('profile');

    if (storedData) {
      profile = JSON.parse(storedData);
    } else {
      profile = {
        avatar: {
          created: "",
          id: 0,
          imageType: "GARAGE_AVATAR",
          pathNormal: "",
          pathSmall: ""
        },
        contactName: "",
        contactPhone: "",
        email: "",
        id: 0,
        orgName: "",
        phone: ""
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return profile;
};

export const storeProfile = (profile) => {
  window.localStorage.setItem('profile', JSON.stringify(profile));
};

const ProfileContext = createContext({
  profile: initialProfile,
  saveProfileContext: () => {},
  removeProfileContext: () => {}
});

export const ProfileProvider = (props) => {
  const { children } = props;
  const [profile, setProfile] = useState(initialProfile);

  useEffect(() => {
    console.log('restoredProfile');
    const restoredProfile = restoreProfile();

    if (restoredProfile && !profile) {
      setProfile(restoredProfile);
    }
  }, []);

  const saveProfile = (updatedProfile) => {
    console.log('saveProfile');
    setProfile(updatedProfile);
    storeProfile(updatedProfile);
  };

  const removeProfile = () => {
    console.log('removeProfile');
    setProfile({
      avatar: {
        created: "",
        id: 0,
        imageType: "GARAGE_AVATAR",
        pathNormal: "",
        pathSmall: ""
      },
      contactName: "",
      contactPhone: "",
      email: "",
      id: 0,
      orgName: "",
      phone: ""
    });
    storeProfile({
      avatar: {
        created: "",
        id: 0,
        imageType: "GARAGE_AVATAR",
        pathNormal: "",
        pathSmall: ""
      },
      contactName: "",
      contactPhone: "",
      email: "",
      id: 0,
      orgName: "",
      phone: ""
    });
  };

  return (
    <ProfileContext.Provider
      value={{
        profile,
        saveProfile,
        removeProfile
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

ProfileProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const ProfileConsumer = ProfileContext.Consumer;

export default ProfileContext;
