import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {config}  from 'config';
import AuthGuard from 'comp/AuthGuard';
import {Link} from '@material-ui/core';

export const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route {...rest} render={props => (
           JSON.parse(localStorage.getItem('user'))
            ? (<AuthGuard><Component {...props}/></AuthGuard>)
            :  <AuthGuard redir={true}/>

    )} />
)
