import merge from 'lodash/merge';
import { createTheme, responsiveFontSizes } from '@material-ui/core/styles';
import { THEMES } from '../constants';
import { lightShadows, darkShadows } from './shadows';

import palette from './palette';
import typography from './typography';
import overrides from './overrides';

import {colors} from '@material-ui/core';

const baseOptions = {
  direction: 'ltr',
  components: {
    MuiAvatar: {
      styleOverrides: {
        fallback: {
          height: '75%',
          width: '75%'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none'
        }
      }
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box'
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%'
        },
        body: {
          height: '100%'
        },
        '#root': {
          height: '100%'
        },
        '#nprogress .bar': {
          zIndex: '2000 !important'
        }
      }
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6'
        }
      }
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden'
        }
      }
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: 'auto',
          marginRight: '16px'
        }
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none'
        }
      }
    }
  },
  typography: {
    button: {
      fontWeight: 600
    },
    fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji"',
    h1: {
      fontWeight: 600,
      fontSize: '3.5rem'
    },
    h2: {
      fontWeight: 600,
      fontSize: '3rem'
    },
    h3: {
      fontWeight: 600,
      fontSize: '2.25rem'
    },
    h4: {
      fontWeight: 600,
      fontSize: '2rem'
    },
    h5: {
      fontWeight: 600,
      fontSize: '1.5rem'
    },
    h6: {
      fontWeight: 600,
      fontSize: '1.125rem'
    },
    overline: {
      fontWeight: 600
    }
  }
};

const themesOptions = {
  [THEMES.LIGHT]: {
    components: {
      MuiInputBase: {
        styleOverrides: {
          input: {
            '&::placeholder': {
              opacity: 0.86,
              color: '#42526e'
            }
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      background: {
        default: '#f4f5f7',
        paper: '#ffffff',
        white: '#ffffff',
        green: 'green'
      },
      taxon4ek: {
        main: "#FFCC00",
        darkText: '#000000'
      },
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'light',
      primary: {
        contrastText: '#ffffff',
        main: colors.blue[700],
        default: colors.blue[700],
        blue: 'rgb(20 0 255)',
        dark: 'rgb(0 35 196)',
        gray: "#37474f"
      },
      inerit: {
        contrastText: '#ffffff',
        main: '#01ab56',
        default: '#ffffff'
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#172b4d',
        doc: '#172b4d',
        secondary: '#6b778c',
        main: colors.blue[700],
        gray: 'rgb(66 66 66 / 60%)',
        grayDark:'#4F4F4F',
        chipDefault: colors.blue[700]
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: lightShadows
  },
  [THEMES.DARK]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      taxon4ek: {
        main: "#FFCC00",
        darkText: '#000000'
      },
      background: {
        default: '#171c24',
        paper: '#222b36',
        white: '#ffffff',
        green: '#ffffff'
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#688eff',
        dark: '#919eab',
        blue: "#688eff",
        default: '#ffffff',
        gray: "#919eab"
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        doc: '#919eab',
        secondary: '#919eab',
        main: '#919eab',
        gray: '#919eab',
        grayDark: '#688eff',
        chipDefault:'#ffffff'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  },
  [THEMES.NATURE]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c'
      },
      taxon4ek: {
        main: "#FFCC00",
        darkText: '#000000'
      },
      background: {
        default: '#1c2531',
        paper: '#293142',
        green: '#01ab56',
        white: '#ffffff',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
        white: '#ffffff'
      },
      mode: 'dark',
      primary: {
        contrastText: '#ffffff',
        main: '#01ab56',
        dark: '#919eab',
        blue: '#01ab56',
        default: '#ffffff',
        gray: "#919eab"
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        doc: '#919eab',
        secondary: '#919eab',
        main: '#919eab',
        chipDefault:'#ffffff'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  },
  [THEMES.COLOR]: {
    components: {
      MuiTableCell: {
        styleOverrides: {
          root: {
            borderBottom: '1px solid rgba(145, 158, 171, 0.24)'
          }
        }
      }
    },
    palette: {
      action: {
        active: '#6b778c',
        darkText: '#919eab'
      },
      background: {
        default: 'rgb(45,67,241,0.24)',
        paper: 'rgb(45,67,241,0.24)',
        green: 'rgb(45,67,241,0.24)',
        white: '#ffffff',
      },
      divider: 'rgba(145, 158, 171, 0.24)',
      error: {
        contrastText: '#ffffff',
        main: '#f44336',
        white: '#ffffff'
      },
      mode: 'color',
      primary: {
        contrastText: 'rgb(45,67,241,0.24)',
        main: 'rgb(45,67,241,0.24)',
        default: '#ffffff',
        gray: "#919eab"
      },
      success: {
        contrastText: '#ffffff',
        main: '#4caf50'
      },
      text: {
        primary: '#ffffff',
        secondary: '#919eab'
      },
      warning: {
        contrastText: '#ffffff',
        main: '#ff9800'
      }
    },
    shadows: darkShadows
  }
};

export const createCustomTheme = (config = {}) => {
  let themeOptions = themesOptions[config.theme];

  if (!themeOptions) {
    console.warn(new Error(`The theme ${config.theme} is not valid`));
    themeOptions = themesOptions[THEMES.LIGHT];
  }

  let theme = createTheme(merge({}, baseOptions, themeOptions,  palette,
    typography,
    overrides, {
    ...(config.roundedCorners && {
      shape: {
        borderRadius: 16
      },
      layout: {
        contentWidth: '100%',
      },
      zIndex: {
        appBar: 1200,
        drawer: 1100,
      }
    })
  }, {
    direction: config.direction,
  }
));

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};
