/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import { Suspense,lazy } from 'react';
import {config}  from 'config';
import {PrivateRoute} from 'authorization/_components';
import LoadingScreen from 'comp/LoadingScreen';

const Loadable = (Component) => (props) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const RouteWithLayout = Loadable(lazy(() => import('../common/RouteWithLayout' /* webpackChunkName: "RouteWithLayout" */)));
const CocoSsdProvider = Loadable(lazy(() => import('contexts/CocoSsdContext' /* webpackChunkName: "CocoSsdProvider" */)));

const NotFound = Loadable(lazy(() => import('pages/NotFound' /* webpackChunkName: "NotFound" */)));
const ServerError = Loadable(lazy(() => import('pages/ServerError' /* webpackChunkName: "ServerError" */)));
const Login = Loadable(lazy(() => import('pages/authentication/Login' /* webpackChunkName: "Login" */)));
const Register = Loadable(lazy(() => import('pages/authentication/Register' /* webpackChunkName: "Register" */)));
const RegisterSuccess = Loadable(lazy(() => import('pages/RegisterSuccess' /* webpackChunkName: "RegisterSuccess" */)));
const Docs = Loadable(lazy(() => import('pages/Docs' /* webpackChunkName: "Docs" */)));
const AdminGarage = Loadable(lazy(() => import('view/AdminGarage' /* webpackChunkName: "AdminGarage" */)));
const SocialProfile = Loadable(lazy(() => import('../views/SocialProfile' /* webpackChunkName: "SocialProfile" */)));
const AddAnnouncement = Loadable(lazy(() => import('view/AddAnnouncement' /* webpackChunkName: "AddAnnouncement" */)));
const MainGaragePage = Loadable(lazy(() => import('view/MainGaragePage' /* webpackChunkName: "MainGaragePage" */)));
const Taxon4ek = Loadable(lazy(() => import('view/Taxon4ek' /* webpackChunkName: "Taxon4ek" */)));

const MainGarageLayout = Loadable(lazy(() => import('../layouts/MainLayout' /* webpackChunkName: "MainLayout" */)));
const GarageAdminLayout = Loadable(lazy(() => import('../layouts/GarageAdminLayout' /* webpackChunkName: "GarageAdminLayout" */)));
const MinimalLayout = Loadable(lazy(() => import('../layouts/Minimal' /* webpackChunkName: "Minimal" */)));
const MainLayout = Loadable(lazy(() => import('../layouts/Main' /* webpackChunkName: "Main" */)));
const MainSiteLayout = Loadable(lazy(() => import('../layouts/MainSite' /* webpackChunkName: "MainSite" */)));
const Taxon4ekLayout = Loadable(lazy(() => import('../layouts/Taxon4ekLayout' /* webpackChunkName: "MainLayout" */)));

const Dashboard = Loadable(lazy(() => import('components/Dashboard' /* webpackChunkName: "Dashboard" */)));
const GuestGuard = Loadable(lazy(() => import('comp/GuestGuard' /* webpackChunkName: "GuestGuard" */)));
const AuthGuard = Loadable(lazy(() => import('comp/AuthGuard' /* webpackChunkName: "AuthGuard" */)));
const DocsLayout = Loadable(lazy(() => import('comp/docs/DocsLayout' /* webpackChunkName: "DocsLayout" */)));

const GarageDashboard = Loadable(lazy(() => import('../views/GarageDashboard' /* webpackChunkName: "GarageDashboard" */)));
const HomeView = Loadable(lazy(() => import('../views/Home' /* webpackChunkName: "Home" */)));
const GarageView = Loadable(lazy(() => import('../views/Garage' /* webpackChunkName: "Garage" */)));
const OrderView = Loadable(lazy(() => import('../views/Order' /* webpackChunkName: "Order" */)));
const OrderPartnerView = Loadable(lazy(() => import('../views/OrderPartner' /* webpackChunkName: "OrderPartner" */)));
const OfferView = Loadable(lazy(() => import('../views/Offer' /* webpackChunkName: "Offer" */)));
const SignupSimpleView = Loadable(lazy(() => import('../views/SignupSimple' /* webpackChunkName: "SignupSimple" */)));
const NotFoundView = Loadable(lazy(() => import('../views/NotFound' /* webpackChunkName: "NotFound" */)));
const AgreeDriverView = Loadable(lazy(() => import('../views/AgreeDriver' /* webpackChunkName: "AgreeDriver" */)));
const AgreePartnerView = Loadable(lazy(() => import('../views/AgreePartner' /* webpackChunkName: "AgreePartner" */)));
const AgreeServiceView = Loadable(lazy(() => import('../views/AgreeService' /* webpackChunkName: "AgreeService" */)));
const RegistrationView = Loadable(lazy(() => import('../views/Registration' /* webpackChunkName: "Registration" */)));
const BankView = Loadable(lazy(() => import('../views/Bank' /* webpackChunkName: "Bank" */)));
const DocsView = Loadable(lazy(() => import('../views/Docs' /* webpackChunkName: "Docs" */)));
const ViewAnnouncement = Loadable(lazy(() => import('../views/ViewAnnouncement' /* webpackChunkName: "ViewAnnouncement" */)));
const Siterules = Loadable(lazy(() => import('../views/Siterules' /* webpackChunkName: "Siterules" */)));
const GeneralCard = Loadable(lazy(() => import('../views/GarageDashboard/components/GeneralCard' /* webpackChunkName: "GeneralCard" */)));

import { ProfileProvider } from 'contexts/ProfileContext';


const routes = [

        {
          path: `${config.baseUrl}/`,
          exact: true,
          component: Loadable(() =>  <RouteWithLayout component={(props) => <MainGaragePage/>}
          exact
          layout={MainGarageLayout}
          path= {`${config.baseUrl}/`}/>)
        },
        {
          path: `${config.baseUrl}/connection`,
          exact: true,
          component: Loadable(() =>  <RouteWithLayout component={(props) => <MainGaragePage/>}
          exact
          layout={MainGarageLayout}
          path= {`${config.baseUrl}/connection`}/>)
        },
        {
          path: `${config.baseUrl}/taxon4ek`,
          exact: true,
          component: Loadable(() =>  <RouteWithLayout component={(props) => <Taxon4ek/>}
          exact
          layout={Taxon4ekLayout}
          path= {`${config.baseUrl}/taxon4ek`}/>)
        },
        {
          path: `${config.baseUrl}/agree-driver` ,
          exact: true,
          component: () => <RouteWithLayout component={AgreeDriverView}
exact
layout={MainLayout}
path= {`${config.baseUrl}/agree-driver`}/>
        },
        {
          path: `${config.baseUrl}/agree-partner`,
          exact: true,
          component: () => <RouteWithLayout component={AgreePartnerView}
exact
layout={MainLayout}
path= {`${config.baseUrl}/agree-partner`}/>
        },
        {
          path: `${config.baseUrl}/agree-terms-of-use`,
          exact: true,
          component: () => <RouteWithLayout component={AgreeServiceView}
exact
layout={MainLayout}
path= {`${config.baseUrl}/agree-terms-of-use`}/>
        },
        {
          path: `${config.baseUrl}/old_garage`,
          exact: true,
          component: () => <RouteWithLayout component={GarageView}
exact
layout={MainLayout}
path= {`${config.baseUrl}/old_garage`}/>
        },
        {
          path: `${config.baseUrl}/add-partner` ,
          exact: true,
          component: () => <RouteWithLayout component={OrderPartnerView}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/add-partner`}/>
        },
        {
          path: `${config.baseUrl}/offer/:id`,
          exact: true,
          component: () => <RouteWithLayout component={OfferView}
exact
layout={MainLayout}
path={`${config.baseUrl}/offer/:id`}/>
        },
        {
          path: `${config.baseUrl}/signup-simple`,
          exact: true,
          component: () => <RouteWithLayout component={SignupSimpleView}
exact
layout={MainLayout}
path= {`${config.baseUrl}/signup-simple`}/>
        },
        {
          path:  `${config.baseUrl}/add`,
          exact: true,
          component: () => <RouteWithLayout component={RegistrationView}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/add`}/>
        },
{
          path: `${config.baseUrl}/garage/admin-garage/register`,
          exact: true,
          component: () =>
  <GuestGuard>
  <Register />
  </GuestGuard>
},
{
  path: `${config.baseUrl}/privacy-policy`,
  exact: true,
  component: () => <RouteWithLayout component={()=><DocsView type="privacyPolicy" />}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/privacy-policy`}/>
},
{
  path: `${config.baseUrl}/about-garage`,
  exact: true,
  component: () => <RouteWithLayout component={()=><DocsView type="aboutGarage" />}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/about-garage`}/>
},
{
  path: `${config.baseUrl}/termsOfUse`,
  exact: true,
  component: () => <RouteWithLayout component={()=><DocsView type="termsOfUse" />}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/termsOfUse`}/>
},
{
  path: `${config.baseUrl}/contract-offer`,
  exact: true,
  component: () => <RouteWithLayout component={()=><DocsView type="contractOffer" />}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/contract-offer`}/>
},
{
  path: `${config.baseUrl}/loyalty-program`,
  exact: true,
  component: () => <RouteWithLayout component={()=><DocsView type="loyaltyProgram" />}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/loyalty-program`}/>
},
{
  path: `${config.baseUrl}/termsOfUseDriver`,
  exact: true,
  component: () => <RouteWithLayout component={()=><DocsView type="termsOfUseDriver" />}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/termsOfUseDriver`}/>
},
{
  path: `${config.baseUrl}/user-agreement-garage-converted`,
  exact: true,
  component: () => <RouteWithLayout component={()=><DocsView type="userAgreementGarageConverted" />}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/user-agreement-garage-converted`}/>
},
{
  path: `${config.baseUrl}/rules-work-drivers`,
  exact: true,
  component: () => <RouteWithLayout component={()=><DocsView type="rulesWorkDrivers" />}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/rules-work-drivers`}/>
},
{
  path: `${config.baseUrl}/quality-standards`,
  exact: true,
  component: () => <RouteWithLayout component={()=><DocsView type="qualityStandards" />}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/quality-standards`}/>
},
{
  path: `${config.baseUrl}/garage/admin-garage/register-success`,
  exact: true,
  component: () => <RegisterSuccess />
},
{
  path: `${config.baseUrl}/garage/admin-garage/login`,
  exact: true,
  component: () =>
  <GuestGuard>
  <Login />
  </GuestGuard>
},
// {
//   path:  `${config.baseUrl}/registration/:code`,
//   exact: true,
//   component: () => <RouteWithLayout component={(props) => <OrderView code={props.match.params.code}/>}
// exact
// layout={MainSiteLayout}
// path= {`${config.baseUrl}/registration/:code`}/>
// },
{
  path: `${config.baseUrl}/registration`,
  exact: true,
  component: () => <OrderView />
},
{
  path: `${config.baseUrl}/registration/:code`,
  exact: true,
  component: () => <OrderView code={props.match.params.code}/>
},
// {
//   path:  `${config.baseUrl}/registration`,
//   exact: true,
//   component: () => <RouteWithLayout component={(props) => <OrderView />}
// exact
// layout={MainSiteLayout}
// path= {`${config.baseUrl}/registration`}/>
// },
{
  path:  `${config.baseUrl}/bank`,
  exact: true,
  component: () => <RouteWithLayout component={BankView}
exact
layout={MainSiteLayout}
path= {`${config.baseUrl}/bank`}/>
},




{
  path:  `${config.baseUrl}/garage`,
  exact: true,
  component: () => <RouteWithLayout component={GarageDashboard}
exact
layout={MainGarageLayout}
path= {`${config.baseUrl}/garage`}/>
},

{
  path:  `${config.baseUrl}/garage/siterules`,
  exact: true,
  component: () => <RouteWithLayout component={Siterules}
exact
layout={MainGarageLayout}
path= {`${config.baseUrl}/garage/siterules`}/>
},
{
  path:  `${config.baseUrl}/garage/inf/:id`,
  exact: true,
  component: () => <RouteWithLayout component={GeneralCard}
  layout={MainGarageLayout}
  path= {`${config.baseUrl}/garage/inf/:id`}/>
},
{
  route: '/garage/admin-garage',
  component: GarageAdminLayout,
  routes: [
    {
      path: `${config.baseUrl}/garage/admin-garage/announcements`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/garage/admin-garage/announcements`} component={AdminGarage} />
    },
    //<PrivateRoute exact path={`${config.baseUrl}/garage/admin-garage/profile`} component={()=> <ProfileProvider><SocialProfile /></ProfileProvider>} />
    {
      path: `${config.baseUrl}/garage/admin-garage/profile`,
      exact: true,
      component:  () => <ProfileProvider><SocialProfile /></ProfileProvider>
    },
    {
      path: `${config.baseUrl}/garage/admin-garage/announcement`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/garage/admin-garage/announcement`} component={AddAnnouncement} />
    },
    {
      path: `${config.baseUrl}/garage/admin-garage/announcement/add`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/garage/admin-garage/announcement/add`} component={AddAnnouncement} />
    },
    {
      path: `${config.baseUrl}/garage/admin-garage/announcement/:id/:tab`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/garage/admin-garage/announcement/:id/:tab`} component={AddAnnouncement} />
    },
    {
      path: `${config.baseUrl}/garage/admin-garage/announcement-peview`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/garage/admin-garage/announcement-peview`} component={ViewAnnouncement} />
    },
    {
      path: `${config.baseUrl}/garage/admin-garage/announcement-peview/:id/:tab`,
      exact: true,
      component:  () => <PrivateRoute exact path={`${config.baseUrl}/garage/admin-garage/announcement-peview/:id/:tab`} component={ViewAnnouncement} />
    },
    {
      path: `${config.baseUrl}/not-found`,
      exact: true,
      component: () =>  <NotFound />
    },
    {
      path: `${config.baseUrl}/500`,
      exact: true,
      component: () => <ServerError />
    },
    {
      component: () => <NotFound />
    }
  ]
},
{
  path: `${config.baseUrl}/not-found`,
  exact: true,
  component: () =>  <NotFound />
},
{
  path: `${config.baseUrl}/500`,
  exact: true,
  component: () => <ServerError />
},
{
  component: () => <NotFound />
}
];

export default routes;
