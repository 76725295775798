import { createContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const initialGoogleProfile = {
  email: '',
  family_name: '',
  given_name: '',
  granted_scopes:'',
  id: null,
  locale:'',
  name: '',
  picture:'',
  verified_email: false,
  phone: ''
};

export const restoreGoogleProfile = () => {
  let googleProfile = null;
  try {
    const storedData = window.localStorage.getItem('googleProfile');

    if (storedData) {
      googleProfile = JSON.parse(storedData);
    } else {
      googleProfile = {
        email: '',
        family_name: '',
        given_name: '',
        granted_scopes:'',
        id: null,
        locale:'',
        name: '',
        picture:'',
        verified_email: false,
        phone: ''
      };
    }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

  return googleProfile;
};

export const storeGoogleProfile = (googleProfile) => {
  window.localStorage.setItem('googleProfile', JSON.stringify(googleProfile));
};

const GoogleProfileContext = createContext({
  googleProfile: initialGoogleProfile,
  saveGoogleProfileContext: () => {},
  removeGoogleProfileContext: () => {}
});

export const GoogleProfileProvider = (props) => {
  const { children } = props;
  const [googleProfile, setGoogleProfile] = useState(initialGoogleProfile);

  useEffect(() => {
    console.log('restoredGoogleProfile');
    const restoredGoogleProfile = restoreGoogleProfile();

    if (restoredGoogleProfile) {
      setGoogleProfile(restoredGoogleProfile);
    }
  }, []);

  const saveGoogleProfile = (updatedGoogleProfile) => {
    console.log('saveGoogleProfile');
    setGoogleProfile(updatedGoogleProfile);
    storeGoogleProfile(updatedGoogleProfile);
  };

  const removeGoogleProfile = () => {
    console.log('removeGoogleProfile');
    setGoogleProfile({
      email: '',
      family_name: '',
      given_name: '',
      granted_scopes:'',
      id: null,
      locale:'',
      name: '',
      picture:'',
      verified_email: false,
      phone: ''
    });
    storeGoogleProfile({
      email: '',
      family_name: '',
      given_name: '',
      granted_scopes:'',
      id: null,
      locale:'',
      name: '',
      picture:'',
      verified_email: false,
      phone: ''
    });
  };

  return (
    <GoogleProfileContext.Provider
      value={{
        googleProfile,
        saveGoogleProfile,
        removeGoogleProfile
      }}
    >
      {children}
    </GoogleProfileContext.Provider>
  );
};

GoogleProfileProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const GoogleProfileConsumer = GoogleProfileContext.Consumer;

export default GoogleProfileContext;
