import { createContext, useEffect, useReducer,useState} from 'react';
import PropTypes from 'prop-types';

const initialMessage = {
  profile: true,
  table: true,
  add: true,
  view: true
};

const FirstMessageContext = createContext({
  firstMessageModel: initialMessage,
  saveMessage: () => {},
  saveMessageProfile: () => {},
  saveMessageTable: () => {},
  saveMessageAdd: () => {},
  saveMessageView: () => {},
});

export const restoreFirstMessage = () => {
//  let storedData = null;
  let message=null;
  try {
      const storedData = window.localStorage.getItem('firstMessage');
      if (storedData) {
        message = JSON.parse(storedData);
      } else {
        message = {
          profile: true,
          table: true,
          add: true,
          view: true
        };
      }
  } catch (err) {
    console.error(err);
    // If stored data is not a strigified JSON this will fail,
    // that's why we catch the error
  }

    return message;
};

export const storeMessage= (message) => {
  window.localStorage.setItem('firstMessage', JSON.stringify(message));
};

export const FirstMessageProvider = (props) => {
  const { children } = props;
  const [firstMessageModel, setFirstMessageModel] = useState(null);

  useEffect(() => {
      if(firstMessageModel==null){
        console.log("firstMessageModel");
        const restored = restoreFirstMessage();
        if (!restored) {
        setFirstMessageModel(initialMessage);
        window.localStorage.setItem('firstMessage', JSON.stringify(initialMessage));
    }else{
    setFirstMessageModel(restored);
  }
}

  }, []);


  const saveMessage = (updated) => {
    setFirstMessageModel(updated);
    storeMessage(updated);
  };

  const saveMessageProfile = () => {
    const message = {
      profile: false,
      table: firstMessageModel.table,
      add: firstMessageModel.add,
      view: firstMessageModel.view
    };
    setFirstMessageModel(message);
    storeMessage(message);
  };

  const saveMessageTable = () => {
    const message = {
      profile: firstMessageModel.profile,
      table: false,
      add: firstMessageModel.add,
      view: firstMessageModel.view
    };
    setFirstMessageModel(message);
    storeMessage(message);
  };

  const saveMessageAdd = () => {
    const message = {
      profile: firstMessageModel.profile,
      table: firstMessageModel.table,
      add: false,
      view: firstMessageModel.view
    };
    setFirstMessageModel(message);
    storeMessage(message);
  };

  const saveMessageView = () => {
    const message = {
      profile: firstMessageModel.profile,
      table: firstMessageModel.table,
      add: firstMessageModel.add,
      view: false
    };
    setFirstMessageModel(message);
    storeMessage(message);
  };

  return (
    <FirstMessageContext.Provider
    value={{
      firstMessageModel,
      saveMessage,
      saveMessageProfile,
      saveMessageTable,
      saveMessageAdd,
      saveMessageView
    }}
    >
      {children}
    </FirstMessageContext.Provider>
  );
};

FirstMessageProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const FirstMessageConsumer = FirstMessageContext.Consumer;

export default FirstMessageContext;
